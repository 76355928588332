export function getAccountDetails() {
  const str = localStorage.getItem("account");
  let account = "";
  if (str) {
    account = JSON.parse(str);
  }

  return account;
}

export function setAccount(account) {
  localStorage.setItem("account", JSON.stringify(account));
}

export function clearAccountDetails() {
  localStorage.setItem("account", null);
}
