import React from "react";
import { Button } from "@mui/material";
import { UploadFileRounded } from "@mui/icons-material";
import FileUpload from "../../../components/FileUpload";

function FileUploadButton() {
  return (
    <FileUpload>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<UploadFileRounded />}
        sx={{ borderRadius: 2 }}>
        Upload File
      </Button>
    </FileUpload>
  );
}

export default FileUploadButton;
