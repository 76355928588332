import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CloseRounded, InfoOutlined } from "@mui/icons-material";
import Toolbar from "../../../components/PDFView/Toolbar";
import PDFView from "../../../components/PDFView";
import FileDetailsDialog from "../../Files/ViewFile/FileDetailsDialog";
import axios from "axios";

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
  },
}));

function FullViewPDF({
  source,
  currentPage,
  file,
  zoomPluginInstance,
  pageNavigationPluginInstance,
  onClose,
}) {
  const { classes } = useStyles();
  const [fileDetails, setFileDetails] = useState({ numPages: 0, size: 0 });
  const [isFileDetailsDialogOpen, setIsFileDetailsDialogOpen] = useState(false);

  const fetchFileSize = async () => {
    try {
      const response = await axios.head(file["file_url"]);
      const fileSizeInBytes = response.headers["content-length"];
      const fileSizeInMegaBytes = (fileSizeInBytes / 1024 ** 2).toFixed(2);
      setFileDetails({ ...fileDetails, size: fileSizeInMegaBytes });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchFileSize();
  }, []);

  const handlePagesChange = (numPages) => {
    setFileDetails({ ...fileDetails, numPages: numPages });
  };

  return (
    <Dialog
      onClose={onClose}
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
      classes={{ paper: classes.root }}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        px={3}
        py={2}
        sx={{
          background: "#323639",
          boxShadow:
            "0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px 0px rgba(0, 0, 0, 0.20)",
        }}>
        <Box display={"flex"} alignItems={"center"}>
          <IconButton edge="start" onClick={onClose} sx={{ mr: 1 }}>
            <CloseRounded sx={{ color: "white" }} />
          </IconButton>
          <Typography variant="h4" sx={{ color: "white" }}>
            {file["name"]}
          </Typography>
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Toolbar
            zoomPluginInstance={zoomPluginInstance}
            pageNavigationPluginInstance={pageNavigationPluginInstance}
          />
          <Tooltip title="File Info">
            <IconButton onClick={() => setIsFileDetailsDialogOpen(true)}>
              <InfoOutlined sx={{ color: "white" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <DialogContent sx={{ paddingY: 0 }}>
        <PDFView
          currentPage={currentPage}
          source={source}
          file={file}
          handlePagesChange={handlePagesChange}
          scale={1}
          zoomPluginInstance={zoomPluginInstance}
          pageNavigationPluginInstance={pageNavigationPluginInstance}
        />
      </DialogContent>
      {isFileDetailsDialogOpen && (
        <FileDetailsDialog
          file={{ ...file, ...fileDetails }}
          onClose={() => setIsFileDetailsDialogOpen(false)}
        />
      )}
    </Dialog>
  );
}

export default FullViewPDF;
