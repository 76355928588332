import { Box, Typography } from "@mui/material";
import React from "react";
import FileUploadButton from "./FileUploadButton";
import Lottie from "react-lottie";
import animationData from "../../../lotties/no_file_uploaded_yet.json";
import { defaultLottieOptions } from "../../../utils/utils";

function EmptyFilesScreen() {
  return (
    <Box
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}>
      <Lottie
        options={{ ...defaultLottieOptions, animationData: animationData }}
        height={192}
        width={192}
      />
      <Typography variant="h4">No files uploaded yet!</Typography>
      <Box mb={1} />
      <Typography>All your files will appear here.</Typography>
      <Box mb={3} />
      <FileUploadButton />
    </Box>
  );
}

export default EmptyFilesScreen;
