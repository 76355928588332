import accountService from "../api/accountService";
import { clearFiles } from "../events/filesEvents";
import { clearConversationAndMessage } from "../events/sessionEvents";
import {
  clearAccountDetails,
  getAccountDetails,
  setAccount,
} from "../storage/localStorage";

export async function getAccountDetailsFromApi(key) {
  try {
    const response = await accountService.getAccount(key);
    return response;
  } catch (error) {
    throw error;
  }
}

export function getAccountIdFromLocal() {
  try {
    const response = getAccountDetails();
    return response["id"];
  } catch (error) {
    console.log(error);
  }
}

export function getAccountNameFromLocal() {
  try {
    const response = getAccountDetails();
    return response["name"];
  } catch (error) {
    console.log(error);
  }
}

export function getAccountDetailsFromLocal() {
  try {
    const response = getAccountDetails();
    return response;
  } catch (error) {
    console.log(error);
  }
}

export function setAccountDetailsInLocal(response) {
  try {
    setAccount(response);
  } catch (err) {
    throw err;
  }
}

export function clearAccountDetailsFromLocal() {
  try {
    clearAccountDetails();
  } catch (err) {
    throw err;
  }
}

export function logout() {
  return async (dispatch) => {
    try {
      clearAccountDetails();
      dispatch(clearFiles());
      dispatch(clearConversationAndMessage());
    } catch (error) {
      throw error;
    }
  };
}
