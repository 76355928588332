import React from "react";
import { Button, Box, useTheme } from "@mui/material";
import { AddRounded } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { setConversationSuccess } from "../../../core/events/sessionEvents";
import { useHistory } from "react-router-dom";
import { CHATS_URL } from "../../../Routes";
import { GradientIconAccent } from "../../../components/GradientIcon";

const useStyles = makeStyles()((theme) => ({
  startIcon: {
    marginRight: 0,
    marginLeft: 0,
  },
}));

function NewChatButton({ isExpand, onClose }) {
  const history = useHistory();
  const theme = useTheme();
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const handleNewChatClick = () => {
    const conversation = {
      id: uuidv4(),
      title: "New Session",
      isNew: true,
    };
    dispatch(setConversationSuccess(conversation));
    history.push({ pathname: CHATS_URL });
    if (onClose) onClose();
  };

  return (
    <Box px={2} py={1}>
      <Button
        onClick={handleNewChatClick}
        classes={{ startIcon: !isExpand ? classes.startIcon : null }}
        fullWidth
        variant="outlined"
        color="secondary"
        startIcon={
          <GradientIconAccent Icon={AddRounded} height={20} width={20} />
        }
        sx={{
          borderRadius: 2,
          minWidth: 30,
          padding: 1.2,
          background: "#fff",
          borderColor: theme.palette.accent,
        }}>
        {isExpand ? "Start Session" : ""}
      </Button>
    </Box>
  );
}

export default NewChatButton;
