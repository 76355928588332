/* eslint-disable react/no-array-index-key */
import React, { Fragment } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Error404View from "./views/404/Error404View";
import Account from "./views/Account";
import GuestGuard from "./components/GuestGuard";
import Files from "./views/Files";
import DashboardLayout from "./views/DashboardLayout";
import Chats from "./views/Chats";

export const FILES_URL = "/files";
export const CHATS_URL = "/sessions";
export const ACCOUNT_KEY_URL = "/account-key";
export const ERROR_URL = "/404";

const routesConfig = [
  {
    exact: true,
    path: "/",
    component: () => <Redirect to={CHATS_URL} />,
  },
  {
    exact: true,
    path: ERROR_URL,
    component: () => <Error404View />,
  },
  {
    exact: true,
    path: CHATS_URL,
    component: () => <Chats />,
    guard: GuestGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: FILES_URL,
    component: () => <Files />,
    guard: GuestGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: ACCOUNT_KEY_URL,
    component: () => <Account />,
  },
  {
    component: () => <Redirect to={ERROR_URL} />,
  },
];

const renderRoutes = (routes) => (
  <Switch>
    {routes.map((route, i) => {
      const Guard = route.guard || Fragment;
      const Layout = route.layout || Fragment;
      const Component = route.component;

      return (
        <Route
          key={i}
          path={route.path}
          exact={route.exact}
          render={(props) => (
            <Guard>
              <Layout>
                {route.routes ? (
                  renderRoutes(route.routes)
                ) : (
                  <Component {...props} />
                )}
              </Layout>
            </Guard>
          )}
        />
      );
    })}
  </Switch>
);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
