//account
export const VIEW_ACCOUNT_DETAILS = "view_account_details";
export const LOGIN_SUCCESS = "login_success";
export const LOGIN_FAILURE = "login_failure";
export const LOGOUT_SUCCESS = "logout_success";

//sessions
export const START_SESSION = "start_session";
export const SOURCE_CLICK = "source_click";
export const FOLLOWUP_BUTTON_CLICK = "followup_button_click";
export const SELECT_SESSION = "select_session";
export const GO_BACK_TO_SESSIONS = "go_back_to_sessions";
export const SCROLL_TO_BOTTOM = "scroll_to_bottom";
export const ASK_QUESTION_SUCCESS = "ask_question_success";
export const ASK_QUESTION_FAILURE = "ask_question_failure";
export const GO_BACK_TO_SESSION = "go_back_to_session";
export const EXPAND_MAIN_QUESTION = "expand_main_question";

//files
export const UPLOAD_FILES_SUCCESS = "upload_files_success";
export const UPLOAD_FILES_FAILURE = "upload_files_failure";
export const FILE_CLICK = "file_click";
export const SORT_FILES = "sort_files";
export const DELETE_FILE_SUCCESS = "delete_file_success";
export const DELETE_FILE_FAILURE = "delete_file_failure";
export const EDIT_FILE_SUCCESS = "edit_file_success";
export const EDIT_FILE_FAILURE = "edit_file_failure";
export const VIEW_FILE_INFO = "view_file_info";
export const GO_BACK_TO_FILES = "go_back_to_files";
