import { getInstance } from "./axios";
import { GET_ACCOUNTS_URL } from "./url";

class AccountService {
  getAccount = (key) =>
    new Promise((resolve, reject) => {
      getInstance()
        .get(GET_ACCOUNTS_URL.format(key))
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
}

const accountService = new AccountService();

export default accountService;
