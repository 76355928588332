import React, { useState, useEffect, useRef } from "react";
import { Box, useTheme, Grid } from "@mui/material";
import {
  createConversation,
  getConversationMessages,
} from "../../../core/repo/conversationRepo";
import { chatWithAI, getChatMetaData } from "../../../core/repo/chatRepo";
import Loader from "../../../components/Loader";
import Results from "./Results";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import FollowUp from "./FollowUp";
import { getAccountDetails } from "../../../core/storage/localStorage";
import StreamMessage from "./StreamMessage";
import { usePostHog } from "posthog-js/react";
import {
  ASK_QUESTION_FAILURE,
  ASK_QUESTION_SUCCESS,
  SOURCE_CLICK,
} from "../../../utils/posthogEvents";
import { useSelector } from "react-redux";
import SourceDialog from "./SourceDialog";
import NewConversationDialog from "../Conversations/NewConversationDialog";
import QueryContainer from "./Query";

function Messages({
  conversation,
  message,
  promptQuestions,
  handleMessageChange,
  handleConversationChange,
  handleAddConversation,
}) {
  const posthog = usePostHog();
  const { enqueueSnackbar } = useSnackbar();
  const account = getAccountDetails();
  const [messages, setMessages] = useState([]);
  const { files } = useSelector((state) => state.files);
  const [isLoading, setIsLoading] = useState(false);
  const [source, setSource] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState(
    files.map((file) => file["id"])
  );
  const [isAnswerLoading, setIsAnswerLoading] = useState(false);
  const [isMetaDataLoading, setIsMetaDataLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [tempQandA, setTempQandA] = useState(null);
  const [queryResponse, setQueryResponse] = useState("");
  const messagesEndRef = useRef();
  const messagesStartRef = useRef();
  const messagesRef = useRef();
  // console.log(messages);

  async function fetchMessages() {
    setIsLoading(true);
    try {
      const response = await getConversationMessages(conversation["id"]);
      const msgs = formQuestionAnswerPairs(response["messages"] || []);
      setMessages(msgs);
      if (msgs.length > 0) {
        const fileIds =
          msgs[msgs.length - 1]["query"]["content_object"]["file_ids"];
        setSelectedFiles(
          !fileIds || fileIds.length === 0
            ? files.map((file) => file["id"])
            : fileIds
        );
      }
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (!conversation["isNew"]) {
      fetchMessages();
    } else {
      setMessages([]);
    }
  }, [conversation["id"]]);

  function formQuestionAnswerPairs(messages) {
    const questionAnswerPairs = [];
    messages = sortMessages(messages);
    messages.forEach((msg) => {
      if (msg["role"] === "assistant") {
        const query = messages.find(
          (m) => m["message_id"] === msg["question_id"]
        );
        if (query["threads"] && query["threads"].length > 0) {
          let threadQuestionAnswerParis = [];
          const threadMessages = sortMessages(query["threads"]);
          threadMessages.forEach((threadMsg) => {
            if (threadMsg["role"] === "assistant") {
              const q = threadMessages.find(
                (t) => t["message_id"] === threadMsg["question_id"]
              );
              threadQuestionAnswerParis.push({ query: q, answer: threadMsg });
            }
          });
          query["threads"] = threadQuestionAnswerParis;
        }
        questionAnswerPairs.push({ query, answer: msg });
      }
    });
    return questionAnswerPairs;
  }

  function sortMessages(messages) {
    return messages.sort(
      (msg1, msg2) =>
        Date.parse(msg1["created_at"]) - Date.parse(msg2["created_at"])
    );
  }

  async function streamMessages(query) {
    let queryId = uuidv4();
    let conv = conversation;
    try {
      setTempQandA({
        query: query,
        response: null,
        queryId: queryId,
      });
      setIsAnswerLoading(true);
      if (conv["isNew"]) {
        handleConversationChange({ ...conv, isNew: false });
        const conversation = await createConversation(account["id"]);
        conv = { ...conversation, title: "New Session" };
        handleConversationChange(conv);
      }
      const questionId = message ? message["query"]["message_id"] : null;
      await chatWithAI(
        query,
        conv["id"],
        queryId,
        questionId,
        true,
        selectedFiles,
        (text) => {
          if (text) {
            setQueryResponse(text);
          }
        }
      );
      setIsAnswerLoading(false);
      posthog.capture(ASK_QUESTION_SUCCESS, {
        isFollowUp: message ? true : false,
      });
    } catch (e) {
      enqueueSnackbar("Could not connect to server. Please try again.", {
        variant: "error",
      });
      posthog.capture(ASK_QUESTION_FAILURE, {
        isFollowUp: message ? true : false,
      });
      console.log(e);
    }
    try {
      setIsMetaDataLoading(true);
      const response = await getChatMetaData(queryId);
      const { ai_response, user_query, title } = response;

      if (message) {
        const threadMessages = message["query"]["threads"] || [];
        const threadMessage = {
          ...message,
          query: {
            ...message["query"],
            threads: [
              ...threadMessages,
              { query: user_query, answer: ai_response },
            ],
          },
        };
        handleMessageChange(threadMessage);
        let tempMessages = messages.map((msg) => {
          if (msg["query"]["message_id"] === message["query"]["message_id"]) {
            return threadMessage;
          }
          return msg;
        });
        setMessages(tempMessages);
      } else {
        if (conv["title"] === "New Session") {
          handleAddConversation({ ...conv, title: title });
        }
        setMessages([...messages, { query: user_query, answer: ai_response }]);
      }
      setIsMetaDataLoading(false);
    } catch (e) {
      console.log(e);
    }
    setQueryResponse("");
    setTempQandA(null);
  }

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [message, messages.length, queryResponse, isAnswerLoading]);

  const handleSourceChange = (source) => {
    if (source) {
      // source["file_id"] = "bdc72d21-8ae4-4d8c-b83b-cbf7b4224144";
      const { file_id } = source;
      const file = files.find((f) => f["id"] === file_id);
      if (file) {
        setSource(source);
      } else {
        setSource(null);
        enqueueSnackbar("File Not Found", {
          variant: "error",
        });
      }
      posthog.capture(SOURCE_CLICK);
    } else {
      setSource(source);
    }
  };

  const handleElaborate = async (message) => {
    handleMessageChange(message);
    const query = "Elaborate";
    let queryId = uuidv4();
    try {
      setTempQandA({
        query: query,
        response: null,
        queryId: queryId,
      });
      setIsAnswerLoading(true);
      const questionId = message["query"]["message_id"];
      await chatWithAI(
        query,
        conversation["id"],
        queryId,
        questionId,
        false,
        selectedFiles,
        (text) => {
          if (text) {
            setQueryResponse(text);
          }
        }
      );
      setIsAnswerLoading(false);
      posthog.capture(ASK_QUESTION_SUCCESS, {
        isFollowUp: true,
      });
    } catch (e) {
      enqueueSnackbar("Could not connect to server. Please try again.", {
        variant: "error",
      });
      posthog.capture(ASK_QUESTION_FAILURE, {
        isFollowUp: true,
      });
      console.log(e);
    }
    try {
      setIsMetaDataLoading(true);
      const response = await getChatMetaData(queryId);
      const { ai_response, user_query } = response;
      const threadMessages = message["query"]["threads"] || [];
      const threadMessage = {
        ...message,
        query: {
          ...message["query"],
          threads: [
            ...threadMessages,
            { query: user_query, answer: ai_response },
          ],
        },
      };
      handleMessageChange(threadMessage);
      let tempMessages = messages.map((msg) => {
        if (msg["query"]["message_id"] === message["query"]["message_id"]) {
          return threadMessage;
        }
        return msg;
      });
      setMessages(tempMessages);
      setIsMetaDataLoading(false);
    } catch (e) {
      console.log(e);
    }
    setQueryResponse("");
    setTempQandA(null);
  };

  return (
    <Grid container height={"100%"}>
      <Grid
        item
        sm={source ? 7 : 12}
        height={"100%"}
        width={"100%"}
        position={"relative"}>
        <Box
          // maxWidth={900}
          display={"flex"}
          flexDirection={"column"}
          height={"100%"}
          // margin={"0 auto"}
        >
          <Box
            ref={messagesRef}
            // px={{ xs: 2, sm: 5 }}
            // py={{ xs: 2, sm: 3 }}
            flex={1}
            overflow={"auto"}>
            <Box
              px={{ xs: 2, sm: 5 }}
              py={{ xs: 2, sm: 3 }}
              maxWidth={900}
              margin={"0 auto"}>
              <Box ref={messagesStartRef} />
              {conversation && conversation["isNew"] ? (
                <NewConversationDialog
                  query={query}
                  promptQuestions={promptQuestions}
                  setQuery={setQuery}
                  message={message}
                  handleStreamMessages={streamMessages}
                  onClose={() => handleConversationChange(null)}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                />
              ) : message ? (
                <FollowUp
                  message={message}
                  handleSourceChange={handleSourceChange}
                />
              ) : (
                <Results
                  messages={messages}
                  messagesRef={messagesRef}
                  handleMessageChange={handleMessageChange}
                  handleSourceChange={handleSourceChange}
                  handleElaborate={handleElaborate}
                />
              )}
              {tempQandA && (
                <StreamMessage
                  numMessages={messages.length}
                  tempQandA={tempQandA}
                  queryResponse={queryResponse}
                  isAnswerLoading={isAnswerLoading}
                  isMetaDataLoading={isMetaDataLoading}
                  message={message}
                />
              )}
              <Box ref={messagesEndRef} />
            </Box>
          </Box>
          <Box width={"100%"} maxWidth={900} margin={"0 auto"}>
            <QueryContainer
              query={query}
              setQuery={setQuery}
              message={message}
              handleStreamMessages={streamMessages}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              messagesRef={messagesRef}
              messagesEndRef={messagesEndRef}
            />
          </Box>
          {isLoading && <Loader />}
        </Box>
      </Grid>
      {source && (
        <SourceDialog source={source} handleSourceChange={handleSourceChange} />
      )}
    </Grid>
  );
}

export default Messages;
