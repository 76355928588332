import React from "react";

function Logo({ variant = "medium" }) {
  let width = 40,
    height = 40,
    borderRadius = "83.333px",
    boxShadow = "5px 5px 10px 0px rgba(159, 144, 239, 0.50)";

  if (variant === "small") {
    width = 29.3;
    height = 29.3;
    borderRadius = "61.069px";
    boxShadow = "3.664px 3.664px 7.328px 0px rgba(159, 144, 239, 0.50)";
  }
  return (
    <img
      src="/images/RAVEN_LOGO.png"
      alt="raven_logo"
      height={height}
      width={width}
      style={{
        borderRadius: borderRadius,
        boxShadow: boxShadow,
      }}
    />
  );
}

export default Logo;
