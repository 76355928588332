import React from "react";
import { Box } from "@mui/material";
import Menu from "./Menu";
import Account from "./Account";
import NewChatButton from "./NewChatButton";
import Logo from "../../../components/Logo";

function Sidebar() {
  return (
    <Box
      sx={{
        width: 72,
        height: "100vh",
        height: "100svh",
        display: "flex",
        flexDirection: "column",
      }}>
      <Box px={2} pt={3} pb={2}>
        <Logo />
      </Box>
      <NewChatButton isExpand={false} />
      <Menu isExpand={false} />
      <Account isExpand={false} />
    </Box>
  );
}

export default Sidebar;
