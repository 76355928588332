import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  useTheme,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { ChevronRightRounded } from "@mui/icons-material";
import {
  getAccountDetailsFromApi,
  setAccountDetailsInLocal,
} from "../../core/repo/accountRepo";
import { useHistory } from "react-router-dom";
import { CHATS_URL } from "../../Routes";
import { makeStyles } from "tss-react/mui";
import { usePostHog } from "posthog-js/react";
import { LOGIN_FAILURE, LOGIN_SUCCESS } from "../../utils/posthogEvents";
import Logo from "../../components/Logo";

const useStyles = makeStyles()((theme) => ({
  container: {
    height: "100vh",
    height: "100svh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function Account() {
  const posthog = usePostHog();
  const { classes } = useStyles();
  const theme = useTheme();
  const buttonRef = useRef();
  const [key, setKey] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const getAccount = async () => {
    try {
      setError(false);
      setIsLoading(true);
      const response = await getAccountDetailsFromApi(key.toLowerCase());
      setAccountDetailsInLocal(response);
      posthog.capture(LOGIN_SUCCESS);
      history.push({ pathname: CHATS_URL });
    } catch (ex) {
      console.log(ex);
      posthog.capture(LOGIN_FAILURE);
      setError(true);
    }

    setIsLoading(false);
  };

  return (
    <Box className={classes.container}>
      <Box
        p={{ md: 5, xs: 3 }}
        boxShadow={"0px 4px 8px 3px rgba(63, 81, 181, 0.12)"}
        borderRadius={6}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}>
          <Logo />
          <Box mb={1} />
          <Typography variant="h3">Welcome to Raven AI</Typography>
          <Box mb={4} />
          <Typography variant="h5" textAlign={"center"}>
            Enter your account key
          </Typography>
        </Box>
        <Box mb={2} />
        <form>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}>
            <TextField
              label="Account key"
              sx={{ width: 300 }}
              InputProps={{ style: { borderRadius: 8 } }}
              value={key}
              onChange={(e) => {
                setKey(e.currentTarget.value);
              }}
            />
            <Box mb={2} />
            {!isLoading ? (
              <Button
                variant="contained"
                ref={buttonRef}
                sx={{ width: 300, height: 45, borderRadius: 2 }}
                onClick={() => getAccount()}
                color="secondary"
                type="submit"
                size="large"
                disabled={!key || key.length === 0}
                endIcon={<ChevronRightRounded />}>
                Get Started
              </Button>
            ) : (
              <CircularProgress color="primary" size={20} />
            )}
            {error && (
              <Typography variant="caption" color={"darkred"} sx={{ mt: 2 }}>
                Error
              </Typography>
            )}
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default Account;
