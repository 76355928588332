import React, { useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import AccountDetailsDialog from "./AccountDetailsDialog";
import { getAccountDetails } from "../../../../core/storage/localStorage";
import { usePostHog } from "posthog-js/react";
import { VIEW_ACCOUNT_DETAILS } from "../../../../utils/posthogEvents";

function Account({ isExpand }) {
  const posthog = usePostHog();
  const account = getAccountDetails();
  const { name } = account;
  const [isAccountDialogOpen, setIsAccountDialogOpen] = useState(false);

  const handleOpen = () => {
    posthog.capture(VIEW_ACCOUNT_DETAILS);
    setIsAccountDialogOpen(true);
  };

  const handleClose = () => {
    setIsAccountDialogOpen(false);
  };

  return (
    <>
      <Box
        p={2}
        display={"flex"}
        alignItems={"center"}
        sx={{ cursor: "pointer" }}
        onClick={handleOpen}>
        <Avatar sx={{ background: "#263238" }}>{name[0]}</Avatar>
        {isExpand && (
          <Typography variant="subtitle2" noWrap sx={{ ml: 1 }}>
            {name}
          </Typography>
        )}
      </Box>
      {isAccountDialogOpen && (
        <AccountDetailsDialog account={account} onClose={handleClose} />
      )}
    </>
  );
}

export default Account;
