import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Lottie from "react-lottie";
import animationData from "../../../lotties/no_sessions_yet.json";
import { defaultLottieOptions } from "../../../utils/utils";
import { AddRounded } from "@mui/icons-material";

function NoSessionsScreen({ handleNewChatClick }) {
  return (
    <Box
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}>
      <Lottie
        options={{ ...defaultLottieOptions, animationData: animationData }}
        height={192}
        width={192}
      />
      <Box mb={2} />
      <Typography variant="h4">No sessions yet!</Typography>
      <Box mb={1} />
      <Typography>All your sessions will appear here.</Typography>
      <Box mb={3} />
      <Button
        onClick={handleNewChatClick}
        variant="contained"
        color="secondary"
        startIcon={<AddRounded />}
        sx={{ borderRadius: 2 }}>
        Start Session
      </Button>
    </Box>
  );
}

export default NoSessionsScreen;
