import React from "react";
import { Box } from "@mui/material";
import File from "./File";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  filesContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    rowGap: 32,
    columnGap: 20,
    padding: 24,
    paddingTop: 0,
    [theme.breakpoints.up(1050)]: {
      width: 900,
      margin: "0 auto",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 0,
      rowGap: 24,
      columnGap: 16,
    },
  },
}));

function Results({
  files,
  handleSelectFile,
  handleDeleteFile,
  handleUpdateFile,
}) {
  const { classes } = useStyles();
  return (
    <Box flex={1} overflow={"auto"}>
      <Box className={classes.filesContainer}>
        {files.map((file) => {
          const { id } = file;
          return (
            <File
              key={id}
              file={file}
              handleSelectFile={handleSelectFile}
              handleDeleteFile={handleDeleteFile}
              handleUpdateFile={handleUpdateFile}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default Results;
