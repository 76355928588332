import React, { useState } from "react";
import { Box, Typography, Chip, useTheme } from "@mui/material";
import { Query, Answer } from "../Message";
import { makeStyles } from "tss-react/mui";
import { Timeline } from "@mui/lab";
import MessageDialog from "./MessageDialog";
import CustomTimlineItem from "../../../../components/CustomTimlineItem";
import { ExpandMoreRounded } from "@mui/icons-material";
import { usePostHog } from "posthog-js/react";
import { EXPAND_MAIN_QUESTION } from "../../../../utils/posthogEvents";
import Sources from "../Message/Sources";

const useStyles = makeStyles()((theme) => ({
  text: {
    wordWrap: "break-word",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
  },
  chip: {
    background: theme.palette.paper,
  },
  expandMoreIcon: {
    marginLeft: 8,
    marginBottom: 4,
    background: "#E0E0E0",
    borderRadius: "50%",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function FollowUp({ message, handleSourceChange }) {
  const { query } = message;
  const messages = query["threads"] || [];

  return (
    <Box>
      <MainQuestion message={message} />
      {messages.length > 0 && (
        <FollowUpResults
          messages={messages}
          handleSourceChange={handleSourceChange}
        />
      )}
    </Box>
  );
}

function MainQuestion({ message }) {
  const posthog = usePostHog();
  const theme = useTheme();
  const { classes } = useStyles();
  const { query } = message;
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsMessageDialogOpen(true);
    posthog.capture(EXPAND_MAIN_QUESTION);
  };

  return (
    <Box p={2} borderRadius={3} bgcolor={theme.palette.background.light} mb={3}>
      <Chip
        size="small"
        label={<span className={classes.gradientText}>Main Question</span>}
        classes={{ root: classes.chip }}
      />
      <Box mt={1} display={"flex"} alignItems={"flex-end"}>
        <Typography
          variant={"h3"}
          fontWeight={500}
          sx={{ whiteSpace: "pre-wrap" }}>
          {query["content_object"]["text"]}
        </Typography>
        <ExpandMoreRounded
          fontSize="small"
          className={classes.expandMoreIcon}
          onClick={handleOpen}
        />
      </Box>
      {isMessageDialogOpen && (
        <MessageDialog
          message={message}
          onClose={() => setIsMessageDialogOpen(false)}
        />
      )}
    </Box>
  );
}

function FollowUpResults({ messages, handleSourceChange }) {
  return (
    <Timeline>
      {messages.map((message, i) => {
        return (
          <FollowUpMessage
            key={i}
            message={message}
            handleSourceChange={handleSourceChange}
          />
        );
      })}
    </Timeline>
  );
}

function FollowUpMessage({ message, handleSourceChange }) {
  const { query, answer } = message;

  return (
    <CustomTimlineItem>
      <Box mb={10}>
        <Query query={query} isFollowup />
        <Answer answer={answer} handleSourceChange={handleSourceChange} />
        {answer["content_object"]["citations"] &&
          answer["content_object"]["citations"].length > 0 &&
          handleSourceChange && (
            <Sources
              citations={answer["content_object"]["citations"] || []}
              handleSourceChange={handleSourceChange}
            />
          )}
      </Box>
    </CustomTimlineItem>
  );
}

export default FollowUp;
