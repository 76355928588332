import React from "react";
import { v4 as uuidv4 } from "uuid";

// function GradientIcon({ sx, Icon, color = "accent" }) {
//   let color1 = "#BF40AE";
//   let color2 = "#6B40BF";
//   let color3 = "#4E40BF";
//   if (color === "light") {
//     color1 = "#732668";
//     color2 = "#402673";
//     color3 = "#2F2673";
//   }
//   return (
//     <>
//       <svg width={0} height={0}>
//         <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
//           <stop stopColor={color1} />
//           <stop offset="0.5" stopColor={color2} />
//           <stop offset="1" stopColor={color3} />
//         </linearGradient>
//       </svg>
//       <Icon sx={{ ...sx, fill: "url(#linearColors)" }} />
//     </>
//   );
// }

export function GradientIconAccent({ Icon, width = 24, height = 24 }) {
  const id = `paint0_linear_${uuidv4()}`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <Icon sx={{ fill: `url(#${id})` }} />
      <defs>
        <linearGradient
          id={id}
          x1={1}
          y1={0}
          x2={1}
          y2={1}
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#BF40AE" />
          <stop offset="0.5" stop-color="#6B40BF" />
          <stop offset="1" stop-color="#4E40BF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function GradientIconLight({ Icon, width = 24, height = 24 }) {
  const id = `paint0_linear_${uuidv4()}`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <Icon sx={{ fill: `url(#${id})` }} />
      <defs>
        <linearGradient
          id={id}
          x1={1}
          y1={0}
          x2={1}
          y2={1}
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#732668" />
          <stop offset="0.5" stop-color="#402673" />
          <stop offset="1" stop-color="#2F2673" />
        </linearGradient>
      </defs>
    </svg>
  );
}
