import moment from "moment";
import { BsDot } from "react-icons/bs";

export function formatDate(date) {
  return (
    <span style={{ display: "flex", alignItems: "center" }}>
      {moment(date).format("DD MMM YYYY")} <BsDot />
      {moment(date).format("h:mm A")}
    </span>
  );
}

export function groupByDate(items) {
  const today = moment();
  const yesterday = moment().subtract(1, "day");
  const groups = {};
  items.forEach((item) => {
    const date = moment(item["created_at"]);
    const dayFormat = date.format("DD MMM YYYY");
    if (date.isSame(today, "day")) {
      if (groups["Today"]) {
        groups["Today"].push(item);
      } else {
        groups["Today"] = [item];
      }
    } else if (date.isSame(yesterday, "day")) {
      if (groups["Yesterday"]) {
        groups["Yesterday"].push(item);
      } else {
        groups["Yesterday"] = [item];
      }
    } else {
      if (groups[dayFormat]) {
        groups[dayFormat].push(item);
      } else {
        groups[dayFormat] = [item];
      }
    }
  });

  const groupsArray = Object.keys(groups).map((date) => {
    return {
      date,
      items: groups[date],
    };
  });
  return groupsArray;
}

export const AI_GENERATED_ILLUSTRATION =
  "https://res.cloudinary.com/ravenapp/image/upload/v1705045125/ai_copilot/AI_Generated_Illustration_pf5ozj.png";

export const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

String.prototype.format = function () {
  return [...arguments].reduce((p, c) => p.replace(/%s/, c), this);
};
