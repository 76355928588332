export const SET_CONVERSATION_SUCCESS = "@session/set-conversation-success";
export const SET_MESSAGE_SUCCESS = "@session/set-message-success";
export const CLEAR_CONVERSATION_MESSAGE = "@session/clear-message-success";

export function setConversationSuccess(conversation) {
  return {
    payload: { conversation },
    type: SET_CONVERSATION_SUCCESS,
  };
}

export function setMessageSuccess(message) {
  return {
    payload: { message },
    type: SET_MESSAGE_SUCCESS,
  };
}

export function clearConversationAndMessage() {
  return {
    type: CLEAR_CONVERSATION_MESSAGE,
  };
}
