const API_BASE_URL = "https://api.staging.ravenapp.dev/copilot";

//Ask AI
export const CHAT_URL = API_BASE_URL + "/ask_ai_stream";
export const CHAT_METADATA_URL = API_BASE_URL + "/ask_ai/metadata";

//Account
export const GET_ACCOUNTS_URL = API_BASE_URL + "/v1/accounts?key=%s";

//Conversations
export const CREATE_CONVERSATION_URL = API_BASE_URL + "/v1/conversations";
export const UPDATE_CONVERSATION_URL = API_BASE_URL + "/v1/conversations/%s";
export const DELETE_CONVERSATION_URL = API_BASE_URL + "/v1/conversations/%s";
export const GET_CONVERSATIONS_URL = API_BASE_URL + "/v1/conversations";
export const GET_CONVERSATION_MESSAGES_URL =
  API_BASE_URL + "/v1/conversations/%s/messages";
export const GET_PROMPT_QUESTIONS = API_BASE_URL + "/v1/prompt_questions";

//Files
export const GET_FILES_URL = API_BASE_URL + "/v1/files";
export const UPLOAD_FILE_URL = API_BASE_URL + "/v1/files";
export const UPDATE_FILE_URL = API_BASE_URL + "/v1/files/%s";
export const DELETE_FILE_URL = API_BASE_URL + "/v1/files/%s";
