import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { formatDate } from "../../../utils/utils";

export default function FileDetailsDialog({ file, onClose }) {
  const { name, size, numPages, created_at } = file;

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      PaperProps={{
        style: {
          maxWidth: "sm",
        },
      }}>
      <Box
        px={3}
        py={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Typography variant="h4">File Info</Typography>
        <IconButton onClick={onClose}>
          <CloseRounded />
        </IconButton>
      </Box>
      <DialogContent dividers>
        <Box display={"flex"} mb={1.5}>
          <Typography
            variant="subtitle2"
            textAlign={"right"}
            sx={{ minWidth: 85 }}>
            File Name:
          </Typography>
          <Box mr={1} />
          <Typography variant="body2" color={"textSecondary"} noWrap>
            {name}
          </Typography>
        </Box>
        <Box display={"flex"} mb={1.5}>
          <Typography
            variant="subtitle2"
            textAlign={"right"}
            sx={{ width: 85 }}>
            Kind:
          </Typography>
          <Box mr={1} />
          <Typography variant="body2" color={"textSecondary"}>
            PDF Document
          </Typography>
        </Box>
        <Box display={"flex"} mb={1.5}>
          <Typography
            variant="subtitle2"
            textAlign={"right"}
            sx={{ width: 85 }}>
            Size:
          </Typography>
          <Box mr={1} />
          <Typography variant="body2" color={"textSecondary"}>
            {`${size} MB`}
          </Typography>
        </Box>
        <Box display={"flex"} mb={1.5}>
          <Typography
            variant="subtitle2"
            textAlign={"right"}
            sx={{ width: 85 }}>
            Pages:
          </Typography>
          <Box mr={1} />
          <Typography variant="body2" color={"textSecondary"}>
            {numPages}
          </Typography>
        </Box>
        <Box display={"flex"}>
          <Typography variant="subtitle2">Uploaded On:</Typography>
          <Box mr={1} />
          <Typography variant="body2" color={"textSecondary"}>
            {formatDate(created_at)}
          </Typography>
        </Box>
        {/* <Box display={"flex"}>
          <Typography
            variant="subtitle2"
            textAlign={"right"}
            sx={{ width: 85 }}>
            Modified On:
          </Typography>
          <Box mr={1} />
          <Typography variant="body2" color={"textSecondary"}>
            {formatDate(updated_at)}
          </Typography>
        </Box> */}
      </DialogContent>
    </Dialog>
  );
}
