import React from "react";
import { Divider, Box, Typography, Skeleton, useTheme } from "@mui/material";
import {
  NotesRounded,
  AutoModeRounded,
  OnlinePredictionRounded,
} from "@mui/icons-material";
import SkeletonLoader from "../../../components/SkeletonLoader";
import AnswerContent from "./Message/AnswerContent";
import CustomTimlineItem from "../../../components/CustomTimlineItem";
import { Query } from "./Message";
import { Timeline } from "@mui/lab";

function StreamMessage({
  message,
  numMessages,
  tempQandA,
  queryResponse,
  isMetaDataLoading,
  isAnswerLoading,
}) {
  return (
    <>
      {message ? (
        <Timeline
          sx={{
            mt:
              message["query"]["threads"] &&
              message["query"]["threads"].length > 0
                ? -3
                : 2,
          }}>
          <CustomTimlineItem>
            <Content
              numMessages={numMessages}
              tempQandA={tempQandA}
              queryResponse={queryResponse}
              isAnswerLoading={isAnswerLoading}
              isMetaDataLoading={isMetaDataLoading}
              message={message}
            />
          </CustomTimlineItem>
        </Timeline>
      ) : (
        <Content
          numMessages={numMessages}
          tempQandA={tempQandA}
          queryResponse={queryResponse}
          isAnswerLoading={isAnswerLoading}
          isMetaDataLoading={isMetaDataLoading}
          message={message}
        />
      )}
    </>
  );
}

function Content({
  numMessages,
  message,
  tempQandA,
  queryResponse,
  isMetaDataLoading,
  isAnswerLoading,
}) {
  const theme = useTheme();

  return (
    <Box mb={message ? 10 : 0}>
      {!message && numMessages > 0 && (
        <Box my={5}>
          <Divider />
        </Box>
      )}
      <Query
        query={{ content_object: { text: tempQandA["query"] } }}
        isFollowup={message}
      />
      {queryResponse ? (
        <Box minHeight={136}>
          <Box mb={5} display={"flex"} alignItems={"center"}>
            {isAnswerLoading ? (
              <AutoModeRounded
                sx={{ color: theme.palette.text.secondary }}
                className={"iconLoading"}
              />
            ) : (
              <NotesRounded sx={{ color: theme.palette.text.secondary }} />
            )}
            <Box mr={1} />
            <Typography variant="h5">Answer</Typography>
          </Box>
          <AnswerContent message={queryResponse} />
        </Box>
      ) : (
        <SkeletonLoader />
      )}
      {isMetaDataLoading && (
        <Box>
          <Box
            my={5}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}>
            <Skeleton
              variant="rounded"
              width={160}
              height={32}
              sx={{ borderRadius: 24 }}
            />
            <Box mr={1.5} />
            <Skeleton
              variant="rounded"
              width={160}
              height={32}
              sx={{ borderRadius: 24 }}
            />
          </Box>
          <Skeleton variant="rounded" width={100} height={24} />
          {/* <Box display={"flex"} alignItems={"center"}>
            <OnlinePredictionRounded
              sx={{ color: theme.palette.text.secondary }}
            />
            <Box mr={1} />
            <Typography variant="h5">Sources</Typography>
          </Box>
          <Box mb={1} />
          <Box display={"flex"}>
            <Skeleton variant="rounded" width={160} height={78} />
            <Box mr={1.5} />
            <Skeleton variant="rounded" width={160} height={78} />
            <Box mr={1.5} />
            <Skeleton variant="rounded" width={160} height={78} />
          </Box> */}
        </Box>
      )}
    </Box>
  );
}
export default StreamMessage;
