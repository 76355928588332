import { getAccountIdFromLocal } from "../repo/accountRepo";
import { getInstance } from "./axios";
import { CHAT_METADATA_URL, CHAT_URL } from "./url";

class ChatService {
  chatWithAI = async (
    query,
    conversationId,
    questionId,
    threadId,
    fileIds,
    concise
  ) => {
    try {
      const response = await fetch(CHAT_URL, {
        method: "POST",
        headers: {
          "x-account-id": getAccountIdFromLocal(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
          conversation_id: conversationId,
          question_id: questionId,
          thread_id: threadId,
          file_ids: fileIds,
          concise: concise,
        }),
      });
      return response.body;
    } catch (error) {
      console.error("Stream fetching failed:", error);
    }
  };

  getChatMetaData = (questionId) =>
    new Promise((resolve, reject) => {
      getInstance()
        .post(CHAT_METADATA_URL, {
          question_id: questionId,
        })
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
}

const chatService = new ChatService();

export default chatService;
