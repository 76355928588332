import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  InputAdornment,
  InputBase,
  useTheme,
  IconButton,
  alpha,
  Typography,
  Button,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  ExpandMoreRounded,
  FilterList,
  SendRounded,
  SnippetFolder,
} from "@mui/icons-material";
import { debounce } from "lodash";
import { usePostHog } from "posthog-js/react";
import { SCROLL_TO_BOTTOM } from "../../../utils/posthogEvents";
import FilesListDialog from "./FilesListDialog";

const useStyles = makeStyles()((theme, _params, classes) => ({
  root: {
    margin: 0,
    background: "#F0F0F0",
    borderRadius: 16,
    padding: "16px 12px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  // textField: {
  //   "&:hover": {
  //     [`& .${classes.container}`]: {
  //       borderColor: theme.palette.text.secondary,
  //     },
  //   },
  //   "&.Mui-focused": {
  //     [`& .${classes.container}`]: {
  //       borderColor: theme.palette.secondary.main,
  //     },
  //   },
  // },
  scrollBottomBtn: {
    position: "absolute",
    top: -32,
    right: -0,
    zIndex: 999,
    borderRadius: 8,
    transform: "translateX(-100%)",
    border: `1px solid ${theme.palette.divider}`,
    background: "rgba(255, 255, 255, 0.40)",
    boxShadow:
      "0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    backdropFilter: "blur(4px)",
    [theme.breakpoints.down("sm")]: {
      top: -40,
      right: -24,
    },
  },
  activeSendBtn: {
    borderRadius: 8,
    cursor: "pointer",
    background: theme.palette.light,
    // "&:hover": {
    //   backgroundColor: alpha(theme.palette.secondary.main, 0.9),
    // },
  },
  disabledSendBtn: {
    borderRadius: 8,
    cursor: "not-allowed",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

export const uid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

function QueryContainer({
  query,
  setQuery,
  message,
  handleStreamMessages,
  selectedFiles,
  setSelectedFiles,
  messagesRef,
  messagesEndRef,
}) {
  const posthog = usePostHog();
  const { classes } = useStyles();
  const theme = useTheme();
  const [show, setShow] = useState(false);
  const ref = useRef();

  const debouncedOnSave = useRef(debounce(handleScroll, 500));

  function handleScroll() {
    setShow(
      messagesRef.current.scrollHeight - messagesRef.current.scrollTop >
        messagesRef.current.clientHeight + 1
    );
  }

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.addEventListener("scroll", debouncedOnSave.current);
    }
  }, []);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      posthog.capture(SCROLL_TO_BOTTOM);
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box
      ref={ref}
      px={{ xs: 2, sm: 5 }}
      pt={{ xs: 1, sm: 3 }}
      pb={{ xs: 1, sm: 4 }}
      sx={{
        position: "relative",
      }}>
      {show && (
        <IconButton
          className={classes.scrollBottomBtn}
          onClick={scrollToBottom}>
          <ExpandMoreRounded />
        </IconButton>
      )}
      <Query
        query={query}
        setQuery={setQuery}
        message={message}
        handleStreamMessages={handleStreamMessages}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
      />
    </Box>
  );
}

export function Query({
  query,
  setQuery,
  message,
  handleStreamMessages,
  selectedFiles,
  setSelectedFiles,
}) {
  const { classes } = useStyles();
  const theme = useTheme();
  function handleQuerySubmit() {
    handleStreamMessages(query);
    setQuery("");
  }

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  function handleOnKeyDown(e) {
    const { key, shiftKey } = e;
    if (key === "Enter" && !shiftKey && query) {
      e.preventDefault();
      handleQuerySubmit();
    }
  }
  return (
    <Box display={"flex"} alignItems={"flex-start"}>
      <FilesListDialog
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
      />
      <Box mr={1} />
      <Box width={"100%"}>
        <InputBase
          fullWidth
          className={classes.root}
          sx={{
            "&:hover": {
              borderColor: theme.palette.text.secondary,
            },
            "&.Mui-focused": {
              borderColor: theme.palette.accent,
              borderWidth: 2,
              margin: "-1px",
            },
          }}
          value={query}
          onChange={handleQueryChange}
          onKeyDown={handleOnKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleQuerySubmit}
                className={
                  query ? classes.activeSendBtn : classes.disabledSendBtn
                }>
                <SendRounded
                  sx={{
                    color: !query ? "inherit" : "#ffffff",
                  }}
                />
              </IconButton>
            </InputAdornment>
          }
          multiline
          maxRows={6}
          placeholder={message ? "Ask a follow-up" : "Ask anything..."}
        />
        {selectedFiles.length > 0 && (
          <Box pl={1.5} mt={0.5}>
            <FormHelperText sx={{ color: theme.palette.success.main }}>
              AI will now answer within selected files.
            </FormHelperText>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default QueryContainer;
