import conversationService from "../api/conversationService";

export async function createConversation(accountId) {
  try {
    const response = await conversationService.createConversation(accountId);
    return response;
  } catch (error) {
    throw error;
  }
}

export function updateConversation(conversation) {
  return async (dispatch) => {
    try {
      const response = await conversationService.updateConversation(
        conversation
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function deleteConversation(conversationId) {
  return async (dispatch) => {
    try {
      await conversationService.deleteConversation(conversationId);
    } catch (error) {
      throw error;
    }
  };
}

export async function getAllConversations() {
  try {
    const response = await conversationService.getAllConversations();
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getConversationMessages(conversationId) {
  try {
    const response = await conversationService.getConversationMessages(
      conversationId
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getPromptQuestions() {
  try {
    const response = await conversationService.getPromptQuestions();
    return response;
  } catch (error) {
    throw error;
  }
}
