import axios from "axios";
import { getAccountDetails } from "../storage/localStorage";

let instance = null;
let id = null;

function getId() {
  let acc = getAccountDetails();
  let id = acc && acc["id"];
  return id;
}

export function getInstance() {
  if (instance) {
    if (id) {
      id = getId();
      return instance;
    } else {
      id = getId();
      instance = axios.create({
        headers: { "x-account-id": getId() },
      });
    }
  } else {
    id = getId();
    instance = axios.create({
      headers: { "x-account-id": getId() },
    });
  }

  return instance;
}
