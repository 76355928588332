import React from "react";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { formatDate } from "../../../utils/utils";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  conversation: {
    // padding: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    // "&:hover": {
    //   background: theme.palette.action.hover,
    // },
  },
}));

function Conversation({ conversation, handleConversationChange, isLast }) {
  const { title, created_at } = conversation;
  const { classes } = useStyles();
  return (
    <>
      <Box
        className={classes.conversation}
        onClick={() => handleConversationChange(conversation)}>
        <Box width={{ xs: "calc(100% - 64px)", sm: "100%" }}>
          <Typography variant="h5" noWrap>
            {title}
          </Typography>
          <Box mb={0.5} />
          <Typography variant="caption" color={"textSecondary"}>
            {formatDate(created_at)}
          </Typography>
        </Box>
        <Box mr={3} />
        <IconButton>
          <ArrowForwardIosRounded />
        </IconButton>
      </Box>
      {!isLast && <Divider sx={{ my: 2 }} />}
    </>
  );
}

export default Conversation;
