import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { deleteFile, updateFile } from "../../core/repo/fileRepo";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import ViewFile from "./ViewFile";
import FilesList from "./FilesList";
import Page from "../../components/Page";
import { makeStyles } from "tss-react/mui";
import { usePostHog } from "posthog-js/react";
import {
  DELETE_FILE_FAILURE,
  DELETE_FILE_SUCCESS,
  EDIT_FILE_FAILURE,
  EDIT_FILE_SUCCESS,
  FILE_CLICK,
  GO_BACK_TO_FILES,
} from "../../utils/posthogEvents";
import { FILES_URL } from "../../Routes";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import {
  setConversationSuccess,
  setMessageSuccess,
} from "../../core/events/sessionEvents";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%",
  },
}));

function Files() {
  const posthog = usePostHog();
  const { classes } = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { files, loading } = useSelector((state) => state.files);
  const [file, setFile] = useState(null);

  useEffect(() => {
    dispatch(setConversationSuccess(null));
    dispatch(setMessageSuccess(null));
    if (files.length > 0) fetchFileFromUrl();
  }, [files]);

  function getFileById(id) {
    return files.find((file) => file["id"] === id);
  }

  function fetchFileFromUrl() {
    const fileId = new URLSearchParams(location.search).get("fileId");
    const file = getFileById(fileId);
    if (file) {
      setFile(file);
    } else {
      history.push({
        pathname: FILES_URL,
      });
    }
  }

  const handleDeleteFile = async (fileId) => {
    try {
      await dispatch(deleteFile(fileId));
      setFile(null);
      enqueueSnackbar("File has been deleted", {
        variant: "success",
      });
      history.push({
        pathname: FILES_URL,
      });
      posthog.capture(DELETE_FILE_SUCCESS);
    } catch (e) {
      posthog.capture(DELETE_FILE_FAILURE);
      console.log(e);
    }
  };

  const handleUpdateFile = async (file) => {
    try {
      await dispatch(updateFile(file));
      enqueueSnackbar("File name has been updated", {
        variant: "success",
      });
      posthog.capture(EDIT_FILE_SUCCESS);
    } catch (e) {
      console.log(e);
      posthog.capture(EDIT_FILE_FAILURE);
    }
  };

  const handleSelectFile = (file) => {
    setFile(file);
    let searchQueryObj = {};
    if (file) {
      posthog.capture(FILE_CLICK);
      searchQueryObj = { fileId: file["id"] };
    } else {
      posthog.capture(GO_BACK_TO_FILES);
    }
    history.push({
      pathname: FILES_URL,
      search: "?" + new URLSearchParams(searchQueryObj).toString(),
    });
  };

  return (
    <Page className={classes.root} title={"Files"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        position={"relative"}>
        {file ? (
          <ViewFile
            file={file}
            handleSelectFile={handleSelectFile}
            handleDeleteFile={handleDeleteFile}
            handleUpdateFile={handleUpdateFile}
          />
        ) : (
          <FilesList
            files={files}
            loading={loading}
            handleSelectFile={handleSelectFile}
            handleDeleteFile={handleDeleteFile}
            handleUpdateFile={handleUpdateFile}
          />
        )}
        {loading && <Loader />}
      </Box>
    </Page>
  );
}

export default Files;
