import React, { useRef, useState, useEffect } from "react";
import { Box, Chip, Divider, useTheme } from "@mui/material";
import Message from "./Message";
import moment from "moment";
import { makeStyles } from "tss-react/mui";
import { debounce } from "lodash";

const useStyles = makeStyles()((theme) => ({
  dividerWrapper: {
    padding: 0,
  },
  sticky: {
    position: "absolute",
    top: 4,
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1,
  },
}));

function Results({
  messages,
  messagesRef,
  handleMessageChange,
  handleElaborate,
  handleSourceChange,
}) {
  messages = groupMessagesByDate(messages);
  return (
    <Box>
      {messages.map((message, i) => {
        return (
          <MessagesGroup
            key={i}
            messageGroup={message}
            messagesRef={messagesRef}
            handleMessageChange={handleMessageChange}
            handleElaborate={handleElaborate}
            handleSourceChange={handleSourceChange}
            isFirstMessage={i === 0}
          />
        );
      })}
    </Box>
  );
}

function MessagesGroup({
  messageGroup,
  messagesRef,
  handleMessageChange,
  handleElaborate,
  handleSourceChange,
  isFirstMessage,
}) {
  const { date, items: messages } = messageGroup;
  return (
    <Box>
      <StickyDate
        date={date}
        isFirstMessage={isFirstMessage}
        messagesRef={messagesRef}
      />
      {messages.map((message, i) => {
        return (
          <Message
            key={i}
            message={message}
            handleMessageChange={handleMessageChange}
            handleElaborate={handleElaborate}
            handleSourceChange={handleSourceChange}
            isLastMessage={i === messages.length - 1}
          />
        );
      })}
    </Box>
  );
}

function StickyDate({ date, isFirstMessage, messagesRef }) {
  const { classes } = useStyles();
  const theme = useTheme();
  const [isSticky, setIsSticky] = useState(false);
  const stickyRef = useRef(null);

  const handleScroll = () => {
    if (stickyRef.current) {
      setIsSticky(messagesRef.current.scrollTop > stickyRef.current.offsetTop);
    }
  };

  const debouncedScroll = useRef(debounce(handleScroll, 5));

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.addEventListener("scroll", debouncedScroll.current);
    }
    return () => {
      if (messagesRef.current)
        messagesRef.current.removeEventListener(
          "scroll",
          debouncedScroll.current
        );
    };
  }, []);

  return (
    <div ref={stickyRef}>
      <Divider
        classes={{ wrapper: classes.dividerWrapper }}
        sx={{
          marginBottom: 4,
          marginTop: !isFirstMessage ? 4 : 0,
        }}>
        <Chip
          variant="outlined"
          label={date}
          sx={{
            background: "white",
            borderColor: theme.palette.divider,
            color: theme.palette.text.secondary,
          }}
        />
      </Divider>
      {isSticky && (
        <Chip
          className={classes.sticky}
          variant="outlined"
          label={date}
          sx={{
            width: 100,
            background: "white",
            borderColor: theme.palette.divider,
            color: theme.palette.text.secondary,
          }}
        />
      )}
    </div>
  );
}

export function groupMessagesByDate(items) {
  const today = moment();
  const yesterday = moment().subtract(1, "day");
  const groups = {};
  items.forEach((item) => {
    const date = moment(item["query"]["created_at"]);
    const dayFormat = date.format("ddd, DD MMM");
    if (date.isSame(today, "day")) {
      if (groups["Today"]) {
        groups["Today"].push(item);
      } else {
        groups["Today"] = [item];
      }
    } else if (date.isSame(yesterday, "day")) {
      if (groups["Yesterday"]) {
        groups["Yesterday"].push(item);
      } else {
        groups["Yesterday"] = [item];
      }
    } else {
      if (groups[dayFormat]) {
        groups[dayFormat].push(item);
      } else {
        groups[dayFormat] = [item];
      }
    }
  });

  const groupsArray = Object.keys(groups).map((date) => {
    return {
      date,
      items: groups[date],
    };
  });
  return groupsArray;
}

export default Results;
