import fileService from "../api/fileService";
import {
  deleteFileSuccess,
  getFilesSuccess,
  updateFileSuccess,
  uploadFileSuccess,
} from "../events/filesEvents";

export function getAllFiles() {
  return async (dispatch) => {
    try {
      const response = await fileService.getAllFiles();
      dispatch(getFilesSuccess(response["files"] || []));
    } catch (error) {
      throw error;
    }
  };
}

export function deleteFile(fileId) {
  return async (dispatch) => {
    try {
      await fileService.deleteFile(fileId);
      dispatch(deleteFileSuccess(fileId));
    } catch (error) {
      throw error;
    }
  };
}

export function updateFile(file) {
  return async (dispatch) => {
    try {
      await fileService.updateFile(file);
      dispatch(updateFileSuccess(file));
    } catch (error) {
      throw error;
    }
  };
}

export function uploadFile(file, onUploadProgress) {
  return async (dispatch) => {
    try {
      const response = await fileService.uploadFile(file, onUploadProgress);
      dispatch(uploadFileSuccess(response));
    } catch (error) {
      throw error;
    }
  };
}
