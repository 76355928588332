export default {
  h1: {
    fontWeight: 500,
    fontSize: 34,
    letterSpacing: "0.25px",
    lineHeight: "40px",
  },
  h2: {
    fontWeight: 500,
    fontSize: 29,
    letterSpacing: "-0.24px",
  },
  h3: {
    fontWeight: 600,
    fontSize: 24,
    letterSpacing: "-0.06px",
    lineHeight: "32px",
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: "-0.06px",
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: "-0.05px",
  },
  overline: {
    fontWeight: 500,
  },
  caption2: {
    fontSize: 10,
    letterSpacing: "0.4px",
  },
};
