import React, { useState, useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Sidebar from "./Sidebar";
import { useDispatch } from "react-redux";
import { getAllFiles } from "../../core/repo/fileRepo";
import Loader from "../../components/Loader";
import Navbar from "./Navbar";
import { usePostHog } from "posthog-js/react";
import { getAccountDetails } from "../../core/storage/localStorage";

function DashboardLayout({ children }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const account = getAccountDetails();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const [isLoading, setIsLoading] = useState(false);

  async function fetchData() {
    setIsLoading(true);
    try {
      await dispatch(getAllFiles());
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
    if (account) posthog.identify(account["name"]);
  }, []);

  return (
    <Box
      display={"flex"}
      flexDirection={{ xs: "column", sm: "row" }}
      width={"100vw"}
      sx={{ height: "100vh", height: "100svh" }}
      bgcolor={"#F4F5FB"}>
      {isMobileView ? <Navbar /> : <Sidebar />}
      <Box
        sx={{ flex: 1, overflow: "auto" }}
        pt={{ xs: 0, sm: 1 }}
        pr={1}
        pb={1}
        pl={{ xs: 1, sm: 0 }}>
        <Box
          bgcolor={theme.palette.background.default}
          border={`1px solid ${theme.palette.divider}`}
          height={"100%"}
          width={"100%"}
          borderRadius={6}
          position={"relative"}>
          {children}
          {isLoading && <Loader />}
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardLayout;
