import { produce } from "immer";
import {
  CLEAR_CONVERSATION_MESSAGE,
  SET_CONVERSATION_SUCCESS,
  SET_MESSAGE_SUCCESS,
} from "../events/sessionEvents";

const initialState = {
  conversation: null,
  message: null,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONVERSATION_SUCCESS: {
      const { conversation } = action.payload;
      return produce(state, (draft) => {
        draft.conversation = conversation;
      });
    }

    case SET_MESSAGE_SUCCESS: {
      const { message } = action.payload;
      return produce(state, (draft) => {
        draft.message = message;
      });
    }

    case CLEAR_CONVERSATION_MESSAGE: {
      return produce(state, (draft) => {
        draft.conversation = null;
        draft.message = null;
      });
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
