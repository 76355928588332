import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import Conversation from "./Conversation";
import { groupByDate } from "../../../utils/utils";
import NoSessionsScreen from "./NoSessionsScreen";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme, _params, classes) => ({
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function Conversations({
  loading,
  conversations,
  handleConversationChange,
  handleNewChatClick,
}) {
  return conversations.length === 0 && !loading ? (
    <NoSessionsScreen handleNewChatClick={handleNewChatClick} />
  ) : (
    <ConversationResults
      conversations={conversations}
      handleConversationChange={handleConversationChange}
    />
  );
}

function ConversationResults({ conversations, handleConversationChange }) {
  conversations = groupByDate(conversations);
  return (
    <Box p={{ xs: 2, md: 3 }} maxWidth={900} margin={"0 auto"}>
      {conversations.map((conversation, i) => {
        return (
          <ConversationGroup
            key={i}
            conversationGroup={conversation}
            handleConversationChange={handleConversationChange}
            isLast={i === conversations.length - 1}
          />
        );
      })}
    </Box>
  );
}

function ConversationGroup({
  conversationGroup,
  handleConversationChange,
  isLast,
}) {
  const { date, items: conversations } = conversationGroup;
  const theme = useTheme();
  const { classes } = useStyles();
  return (
    <Box mb={isLast ? 0 : 3}>
      <Box px={2} pb={1}>
        <Typography variant="subtitle2" className={classes.gradientText}>
          {date}
        </Typography>
      </Box>
      <Box p={2} borderRadius={3} bgcolor={theme.palette.background.light}>
        {conversations.map((conversation, i) => {
          return (
            <Conversation
              key={i}
              conversation={conversation}
              handleConversationChange={handleConversationChange}
              isLast={i === conversations.length - 1}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default Conversations;
