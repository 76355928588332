import React, { useState } from "react";
import {
  Typography,
  Box,
  useTheme,
  Chip,
  Link,
  useMediaQuery,
} from "@mui/material";
import { AI_GENERATED_ILLUSTRATION } from "../../../utils/utils";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: 16,
    borderRadius: 16,
    background: theme.palette.paper,
    boxShadow: "0px 4px 8px 3px rgba(63, 81, 181, 0.12)",
    [theme.breakpoints.up("sm")]: {
      padding: 24,
      flexDirection: "row",
    },
  },
  imageContainer: {
    borderRadius: "50%",
    border: "10px solid #CFD3EC",
    marginBottom: 16,
    marginRight: 0,
    width: 169,
    height: 169,
    [theme.breakpoints.up("sm")]: {
      width: 260,
      height: 260,
      marginBottom: 0,
      borderWidth: 16,
      marginRight: 32,
    },
  },
  chip: {
    padding: "4px 10px",
    border: `1px solid #BDBDBD`,
    borderRadius: 100,
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
}));

function EmptyConversation({ promptQuestions, setQuery }) {
  const theme = useTheme();
  const { classes } = useStyles();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box className={classes.root}>
      <img
        src={AI_GENERATED_ILLUSTRATION}
        alt="ai_illustration"
        className={classes.imageContainer}
      />
      <Box>
        <Typography variant="h3" textAlign={{ xs: "center", sm: "left" }}>
          Converse with your {isXS && <br />}
          <span style={{ color: theme.palette.primary.main }}>
            Raven AI
          </span>
        </Typography>
        <Box mb={2} />
        {!isXS && (
          <>
            <Typography>
              Empowered by the wisdom within your uploaded documents, I'm here
              to provide precise answers swiftly, each accompanied by its source
              for your peace of mind.
            </Typography>
            <Box mb={3} />
          </>
        )}
        <Typography variant="h5">
          Ready to assist: How may I help you today?
        </Typography>
        {promptQuestions.length > 0 && (
          <Box mt={2}>
            {promptQuestions.map((promptQuestion, i) => {
              return (
                <Box
                  key={i}
                  className={classes.chip}
                  mb={i !== promptQuestions.length - 1 ? 2 : 0}
                  onClick={() => setQuery(promptQuestion)}>
                  <Typography fontSize={13} sx={{ whiteSpace: "pre-wrap" }}>
                    {promptQuestion}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default EmptyConversation;
