import React, { useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  IconButton,
  useTheme,
  Chip,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  ArrowOutward,
  ExpandLessRounded,
  ExpandMoreRounded,
  OnlinePredictionRounded,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { GradientIconLight } from "../../../../components/GradientIcon";
import { PDFIcon } from "../../../../components/CustomIcons";

const useStyles = makeStyles()((theme) => ({
  text: {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    // minHeight: 40,
  },
  citationContainer: {
    cursor: "pointer",
    minWidth: 280,
    maxWidth: 280,
    borderRadius: 12,
    background: theme.palette.paper,
    padding: 12,
    // "&:hover": {
    //   background: theme.palette.action.hover,
    // },
  },
  tabContainer: {
    width: "100%",
  },
  carouselBtn: {
    color: theme.palette.text.secondary,
    background: "#FFFFFF",
    boxShadow:
      "0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.20)",
  },
  label: {
    display: "flex",
    paddingLeft: 8,
    paddingRight: 8,
  },
  icon: {
    fontSize: "7px !important",
  },
}));

function Sources({ citations, handleSourceChange }) {
  const theme = useTheme();
  const { classes } = useStyles();
  const { files } = useSelector((state) => state.files);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box mt={5}>
      <Box display={"flex"} alignItems={"center"}>
        <OnlinePredictionRounded
          sx={{ color: theme.palette.action.disabled }}
        />
        <Box mr={1} />
        <Typography variant="subtitle2" color={"textSecondary"}>
          Sources
        </Typography>
        <Box mr={1} />
        <Chip
          size="small"
          onClick={() => setIsOpen(!isOpen)}
          sx={{ background: "#E8EAF6", cursor: "pointer" }}
          classes={{ label: classes.label }}
          label={
            <GradientIconLight
              Icon={isOpen ? ExpandLessRounded : ExpandMoreRounded}
              width={16}
              height={16}
            />
          }
        />
      </Box>
      {isOpen && (
        <>
          <Box mb={2} />
          <Tabs
            sx={{ position: "relative" }}
            scrollButtons="auto"
            variant="scrollable"
            ScrollButtonComponent={(props) => {
              const { direction, disabled } = props;
              if (direction === "left" && !disabled) {
                return (
                  <Box
                    height={101.31}
                    width={78}
                    display={"flex"}
                    alignItems={"center"}
                    position={"absolute"}
                    left={0}
                    zIndex={1}
                    sx={{
                      background:
                        "linear-gradient(268deg, rgba(255, 255, 255, 0.00) 1.31%, #FFF 79.43%)",
                    }}>
                    <IconButton
                      {...props}
                      sx={{ marginLeft: 0.25 }}
                      className={classes.carouselBtn}
                      disableRipple>
                      <ArrowBackIosRounded sx={{ fontSize: 16 }} />
                    </IconButton>
                  </Box>
                );
              } else if (direction === "right" && !disabled) {
                return (
                  <Box
                    height={101.31}
                    width={78}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                    position={"absolute"}
                    right={0}
                    sx={{
                      background:
                        "linear-gradient(268deg, #FFF 19.96%, rgba(255, 255, 255, 0.00) 98.08%)",
                    }}>
                    <IconButton
                      {...props}
                      sx={{ marginRight: 0.25 }}
                      className={classes.carouselBtn}
                      disableRipple>
                      <ArrowForwardIosRounded sx={{ fontSize: 16 }} />
                    </IconButton>
                  </Box>
                );
              } else {
                return null;
              }
            }}>
            {citations.map((citation, i) => {
              const { section, file_id } = citation;
              const file = files.find((f) => f["id"] === file_id);

              return (
                <Box
                  flex={1}
                  display={"flex"}
                  flexDirection={"column"}
                  key={i}
                  mr={1.5}
                  className={classes.citationContainer}
                  onClick={() => handleSourceChange(citation)}>
                  <Box flex={1} overflow={"auto"}>
                    <Typography variant="body2" className={classes.text}>
                      {section || "."}
                    </Typography>
                  </Box>
                  <Box mb={1.5} />
                  {file && (
                    <Box>
                      <Tooltip title={file["name"]}>
                        <Chip
                          sx={{ paddingLeft: 0.5 }}
                          classes={{ icon: classes.icon }}
                          label={
                            <Typography variant="caption">
                              {file["name"]}
                            </Typography>
                          }
                          size="small"
                          icon={<PDFIcon />}
                          deleteIcon={<ArrowOutward />}
                          onDelete={() => {}}
                        />
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              );
            })}
          </Tabs>
        </>
      )}
      {/* </Box> */}
    </Box>
  );
}

export default Sources;
