import React from "react";
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { ACCOUNT_KEY_URL } from "../../../../Routes";
import { logout } from "../../../../core/repo/accountRepo";
import { useDispatch } from "react-redux";
import { usePostHog } from "posthog-js/react";
import { LOGOUT_SUCCESS } from "../../../../utils/posthogEvents";

function AccountDetailsDialog({ account, onClose }) {
  const posthog = usePostHog();
  const { name } = account;
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    posthog.capture(LOGOUT_SUCCESS);
    history.push({ pathname: ACCOUNT_KEY_URL });
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={"xs"}
      sx={{ zIndex: 1700 }}>
      <Box
        py={2}
        px={3}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Typography variant="h4">My Account</Typography>
        <IconButton onClick={onClose}>
          <CloseRounded color={theme.palette.text.secondary} />
        </IconButton>
      </Box>
      <Divider />
      <Box
        px={3}
        py={2.5}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Typography variant="h5">{name}</Typography>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ borderRadius: 2 }}
          onClick={handleLogout}>
          Sign Out
        </Button>
      </Box>
    </Dialog>
  );
}

export default AccountDetailsDialog;
