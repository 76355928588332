import React from "react";
import { Box, Typography, IconButton, Divider, Tooltip } from "@mui/material";
import {
  MdOutlineArrowDropDownCircle,
  MdZoomOut,
  MdZoomIn,
} from "react-icons/md";
import { FitScreen } from "@mui/icons-material";

function Toolbar({ zoomPluginInstance, pageNavigationPluginInstance }) {
  return (
    <Box display={"flex"} alignItems={"center"}>
      <CurrentPageInputPlugin
        pageNavigationPluginInstance={pageNavigationPluginInstance}
      />
      <NextPagePlugin
        pageNavigationPluginInstance={pageNavigationPluginInstance}
      />
      <PrevPagePlugin
        pageNavigationPluginInstance={pageNavigationPluginInstance}
      />
      <Divider
        orientation="vertical"
        sx={{ height: 24, mx: 1, borderColor: "rgba(255, 255, 255, 0.60)" }}
      />
      <ZoomOutPlugin zoomPluginInstance={zoomPluginInstance} />
      <ZoomInPlugin zoomPluginInstance={zoomPluginInstance} />
      <FitScreenPlugin zoomPluginInstance={zoomPluginInstance} />
      <Divider
        orientation="vertical"
        sx={{ height: 24, mx: 1, borderColor: "rgba(255, 255, 255, 0.60)" }}
      />
    </Box>
  );
}

export function CurrentPagePlugin({ pageNavigationPluginInstance }) {
  const { CurrentPageLabel, NumberOfPages } = pageNavigationPluginInstance;
  return (
    <Box display={"flex"} alignItems={"center"} mr={1}>
      <Typography variant="subtitle1" sx={{ marginRight: 0.5 }}>
        Page
      </Typography>
      <Typography>
        <CurrentPageLabel>{(props) => props.currentPage + 1}</CurrentPageLabel>
      </Typography>
      <Typography sx={{ marginX: 0.5 }}>/</Typography>
      <Typography>
        <NumberOfPages />
      </Typography>
    </Box>
  );
}

export function CurrentPageInputPlugin({ pageNavigationPluginInstance }) {
  const { CurrentPageInput, NumberOfPages } = pageNavigationPluginInstance;
  return (
    <Box display={"flex"} alignItems={"center"} mr={1}>
      <Typography variant="subtitle1" sx={{ color: "white", marginRight: 1 }}>
        Page
      </Typography>
      <CurrentPageInput />
      <Typography sx={{ marginRight: 1, color: "white" }}>/</Typography>
      <Typography sx={{ color: "white" }}>
        <NumberOfPages />
      </Typography>
    </Box>
  );
}

export function NextPagePlugin({ pageNavigationPluginInstance }) {
  const { jumpToNextPage } = pageNavigationPluginInstance;
  return (
    <Tooltip title="Next Page">
      <IconButton onClick={jumpToNextPage}>
        <MdOutlineArrowDropDownCircle style={{ color: "white" }} />
      </IconButton>
    </Tooltip>
  );
}

export function PrevPagePlugin({ pageNavigationPluginInstance }) {
  const { jumpToPreviousPage } = pageNavigationPluginInstance;
  return (
    <Tooltip title="Prev Page">
      <IconButton onClick={jumpToPreviousPage}>
        <MdOutlineArrowDropDownCircle
          style={{ transform: "rotate(180deg)", color: "white" }}
        />
      </IconButton>
    </Tooltip>
  );
}

export function ZoomInPlugin({ zoomPluginInstance }) {
  const { ZoomIn } = zoomPluginInstance;
  return (
    <ZoomIn>
      {(props) => (
        <Tooltip title="Zoom In">
          <IconButton onClick={props.onClick}>
            <MdZoomIn style={{ color: "white" }} />
          </IconButton>
        </Tooltip>
      )}
    </ZoomIn>
  );
}

export function ZoomOutPlugin({ zoomPluginInstance }) {
  const { ZoomOut } = zoomPluginInstance;
  return (
    <ZoomOut>
      {(props) => (
        <Tooltip title="Zoom Out">
          <IconButton onClick={props.onClick}>
            <MdZoomOut style={{ color: "white" }} />
          </IconButton>
        </Tooltip>
      )}
    </ZoomOut>
  );
}

export function FitScreenPlugin({ zoomPluginInstance }) {
  const { zoomTo } = zoomPluginInstance;
  return (
    <Tooltip title="View Original">
      <IconButton onClick={() => zoomTo(1)}>
        <FitScreen sx={{ color: "white" }} />
      </IconButton>
    </Tooltip>
  );
}

export default Toolbar;
