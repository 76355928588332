import React from "react";
import { Box, Typography } from "@mui/material";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

export function getMessageContent(message) {
  const IMAGE_REGEX = /!\[(.*?)\]\((.*?)\)/g;
  const messageParts = message.split("\n"); // split by newline
  return (
    <>
      {messageParts.map((part, index) => {
        const imageUrl = part.match(IMAGE_REGEX);
        // if (imageUrl && imageUrl.length > 0) {
        //   // if part includes an image
        //   // extract actual url from markdown image format
        //   const alt = imageUrl[0].replace(IMAGE_REGEX, "$1");
        //   const link = imageUrl[0].replace(IMAGE_REGEX, "$2");
        //   return (
        //     <img
        //       key={index}
        //       src={link}
        //       alt={alt}
        // style={{ margin: "8px 0px", maxWidth: "100%" }}
        //     />
        //   ); // render image
        // } else {
        // return <React.Fragment key={index}>{`${part}\n`}</React.Fragment>;
        return (
          <Markdown
            key={index}
            remarkPlugins={[remarkGfm]}
            components={{
              code(props) {
                const { children, className, ...rest } = props;
                return (
                  <code
                    {...rest}
                    className={className}
                    style={{ whiteSpace: "pre-wrap" }}>
                    {children}
                  </code>
                );
              },
            }}>
            {`${part}\n`}
          </Markdown>
        );
        // }
      })}
    </>
  );
}

function AnswerContent({ message, handleSourceChange = () => {} }) {
  const transformLinkUri = (uri) => {
    return uri;
  };
  return (
    <Box>
      <Markdown
        remarkPlugins={[remarkGfm]}
        urlTransform={transformLinkUri}
        components={{
          code(props) {
            const { children, className, ...rest } = props;
            return (
              <code
                {...rest}
                className={className}
                style={{ whiteSpace: "pre-wrap" }}>
                {children}
              </code>
            );
          },
          img(props) {
            const { ...rest } = props;
            return <img {...rest} style={{ maxWidth: "100%" }} />;
          },
          a: ({ children, href }) => {
            const url = decodeURI(href);
            // Use regular expressions to extract the pg and loc values
            // const pgMatch = url.match(/pg=(\d+)/);
            // const locMatch = url.match(/loc=\[([0-9,]+)\]/);

            // const pg = pgMatch ? pgMatch[1] : null;
            // const loc = locMatch ? locMatch[1].split(",").map(Number) : null;
            return href.startsWith("loc://") ? null : (
              <a href={href}>{children}</a>
            );
          },
        }}>
        {message}
      </Markdown>
    </Box>
  );
}

export default AnswerContent;
