import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import {} from "./utils/utils";
import { SnackbarProvider, MaterialDesignContent } from "notistack";
import { CloseRounded, WarningAmber } from "@mui/icons-material";
import Routes from "./Routes";
import PostHogPageviewTracker from "./components/PostHugPageviewTracker";
import { styled } from "@mui/material/styles";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-warning": {
    backgroundColor: "#EF6C00",
  },
  "&.notistack-MuiContent-info": {
    backgroundColor: "#0288D1",
  },
}));

function App() {
  let snackbarRef = null;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        Components={{
          warning: StyledMaterialDesignContent,
          info: StyledMaterialDesignContent,
        }}
        iconVariant={{
          warning: <WarningAmber fontSize="small" sx={{ marginRight: 1 }} />,
        }}
        maxSnack={1}
        ref={(ele) => {
          snackbarRef = ele;
        }}
        onClose={(event, reason, key) => {
          if (reason === "clickaway") {
            snackbarRef.closeSnackbar(key);
          }
        }}
        action={(snackbarId) => (
          <CloseRounded
            onClick={() => snackbarRef.closeSnackbar(snackbarId)}
            fontSize="small"
            style={{ cursor: "pointer" }}
          />
        )}
        autoHideDuration={3000}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}>
        <Router>
          <PostHogPageviewTracker />
          <Routes />
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
