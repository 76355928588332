import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Answer } from "../Message";

export default function MessageDialog({ message, onClose }) {
  const { query, answer } = message;
  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md">
      <DialogTitle variant="h3" sx={{ display: "flex", alignItems: "center" }}>
        {query["content_object"]["text"]}
      </DialogTitle>
      <DialogContent>
        <Answer answer={answer} />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
