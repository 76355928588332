import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  IconButton,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  useTheme,
} from "@mui/material";
import {
  AddRounded,
  ArrowBackIosRounded,
  BorderColor,
  Delete,
  MoreVert,
} from "@mui/icons-material";
import { usePostHog } from "posthog-js/react";
import {
  GO_BACK_TO_SESSION,
  GO_BACK_TO_SESSIONS,
} from "../../utils/posthogEvents";
import {
  deleteConversation,
  updateConversation,
} from "../../core/repo/conversationRepo";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import EditConversationDialog from "./Conversations/EditConversationDialog";

function Header({
  conversation,
  message,
  handleNewChatClick,
  handleConversationChange,
  handleMessageChange,
  handleRemoveConversation,
  handleUpdateConversation,
}) {
  const posthog = usePostHog();
  const numFollowUp =
    message && message["query"]["threads"]
      ? message["query"]["threads"].length
      : 0;

  const handleBackToMessages = () => {
    handleMessageChange(null);
    posthog.capture(GO_BACK_TO_SESSION);
  };

  const handleBackToConversations = () => {
    handleConversationChange(null);
    posthog.capture(GO_BACK_TO_SESSIONS);
  };

  const handleBackMobileView = () => {
    if (message) {
      handleBackToMessages();
    } else {
      handleBackToConversations();
    }
  };

  return (
    <>
      <Box
        px={{ xs: 2, sm: 3 }}
        py={2}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}>
        {conversation ? (
          <Box width={"100%"} display={"flex"} alignItems={"center"}>
            <IconButton
              edge="start"
              sx={{ mr: 1 }}
              onClick={handleBackMobileView}>
              <ArrowBackIosRounded fontSize="small" />
            </IconButton>
            {message ? (
              <Box
                width={"calc(100% - 36px)"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}>
                <Typography variant="h4" noWrap maxWidth={600}>
                  {`Follow-Ups: ${message["query"]["content_object"]["text"]}`}
                </Typography>
                {numFollowUp > 0 && (
                  <Chip
                    label={`${numFollowUp} Follow-Up${
                      numFollowUp > 1 ? "s" : ""
                    }`}
                    sx={{ marginLeft: 1 }}
                  />
                )}
              </Box>
            ) : (
              <SessionHeader
                conversation={conversation}
                handleRemoveConversation={handleRemoveConversation}
                handleUpdateCurrentConversation={handleUpdateConversation}
              />
            )}
          </Box>
        ) : (
          <>
            <Typography variant="h4">Sessions</Typography>
            <Button
              onClick={handleNewChatClick}
              variant="contained"
              color="secondary"
              startIcon={<AddRounded />}>
              Start Session
            </Button>
          </>
        )}
      </Box>
      <Divider />
    </>
  );
}

function SessionHeader({
  conversation,
  handleRemoveConversation,
  handleUpdateCurrentConversation,
}) {
  const { id } = conversation;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleUpdateConversation(conversation) {
    try {
      const res = await dispatch(updateConversation(conversation));
      handleUpdateCurrentConversation(conversation);
      enqueueSnackbar("Session has been renamed", { variant: "success" });
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Failed to rename session", { variant: "error" });
    }
  }

  async function handleDeleteConversation(conversationId) {
    try {
      await dispatch(deleteConversation(conversationId));
      handleRemoveConversation(conversation);
      enqueueSnackbar("Session has been deleted", { variant: "success" });
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Failed to delete session", { variant: "error" });
    }
  }

  return (
    <Box
      width={"calc(100% - 36px)"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}>
      <Typography variant="h4" noWrap maxWidth={600}>
        {conversation["title"]}
      </Typography>
      <IconButton edge="end" onClick={handleOpen}>
        <MoreVert />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ horizontal: "center" }}>
        <MenuItem onClick={() => setIsEditDialogOpen(true)}>
          <ListItemIcon>
            <BorderColor />
          </ListItemIcon>
          <ListItemText>Rename Session</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setIsDeleteDialogOpen(true)}>
          <ListItemIcon>
            <Delete sx={{ color: theme.palette.error.main }} />
          </ListItemIcon>
          <ListItemText sx={{ color: theme.palette.error.main }}>
            Delete Session
          </ListItemText>
        </MenuItem>
      </Menu>
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          title={"Delete Session"}
          content={`Do you really want to delete this chat? This will permanently delete the conversations and follow-ups.`}
          noLabel={"cancel"}
          yesLabel={"yes,delete"}
          onNo={() => setIsDeleteDialogOpen(false)}
          onYes={() => handleDeleteConversation(id)}
          isDelete={true}
        />
      )}
      {isEditDialogOpen && (
        <EditConversationDialog
          conversation={conversation}
          handleUpdateConversation={handleUpdateConversation}
          onClose={() => setIsEditDialogOpen(false)}
        />
      )}
    </Box>
  );
}

export default Header;
