import React from "react";
import {
  Box,
  Typography,
  Divider,
  useTheme,
  Chip,
  IconButton,
  Button,
} from "@mui/material";
import Sources from "./Sources";
import {
  ArrowForwardIosRounded,
  ChevronRightRounded,
  FiberManualRecord,
  NotesRounded,
  ShortcutRounded,
} from "@mui/icons-material";
import AnswerContent from "./AnswerContent";
import moment from "moment";
import { GradientIconAccent } from "../../../../components/GradientIcon";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  label: {
    paddingLeft: 8,
  },
}));

function Message({
  message,
  handleMessageChange,
  handleElaborate,
  handleSourceChange,
  isLastMessage,
}) {
  const theme = useTheme();
  const { classes } = useStyles();
  const { query, answer } = message;
  const numFollowUp = query["threads"] ? query["threads"].length : 0;

  function getDate(date) {
    date = moment(date);
    const today = moment();
    const yesterday = moment().subtract(1, "day");
    if (date.isSame(today, "day")) {
      return { part1: "Today", part2: date.format("h:mm A") };
    } else if (date.isSame(yesterday, "day")) {
      return { part1: "Yesterday", part2: date.format("h:mm A") };
    }

    return {
      part1: date.format("DD MMM YYYY"),
      part2: date.format("h:mm A"),
    };
  }

  return (
    <Box>
      <Query query={query} />
      <Answer answer={answer} handleSourceChange={handleSourceChange} />
      <Box display={"flex"} alignItems={"center"} mt={5}>
        {numFollowUp === 0 ? (
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            alignItems={{ md: "center", xs: "start" }}
            justifyContent={"flex-end"}>
            {numFollowUp === 0 &&
              answer["content_object"]["text"] !==
                "Sorry, I couldn't find the answer for this question." && (
                <Chip
                  classes={{ label: classes.label }}
                  label={
                    <Typography
                      variant="subtitle2"
                      className={classes.gradientText}>
                      Ask to Elaborate
                    </Typography>
                  }
                  variant="outlined"
                  color="secondary"
                  icon={
                    <GradientIconAccent
                      Icon={ShortcutRounded}
                      sx={{ transform: "scaleX(-1) rotate(180deg)" }}
                    />
                  }
                  sx={{
                    marginRight: { md: 1.5, xs: 0 },
                    marginBottom: { xs: 2, md: 0 },
                    borderRadius: 6,
                    borderTopLeftRadius: 0,
                    paddingLeft: 1.5,
                    borderColor: "#BF40AE",
                  }}
                  onClick={() => handleElaborate(message)}
                />
              )}
            <Chip
              classes={{ label: classes.label }}
              label={
                <Typography
                  variant="subtitle2"
                  className={classes.gradientText}>
                  {numFollowUp > 0
                    ? `${numFollowUp} Follow-Up${numFollowUp > 1 ? "s" : ""}`
                    : "Ask a follow-up question"}
                </Typography>
              }
              variant="outlined"
              color="secondary"
              icon={
                <GradientIconAccent
                  Icon={ShortcutRounded}
                  sx={{ transform: "scaleX(-1) rotate(180deg)" }}
                />
              }
              sx={{
                borderRadius: 6,
                borderTopLeftRadius: 0,
                paddingLeft: 1.5,
                borderColor: "#BF40AE",
              }}
              onClick={() => handleMessageChange(message)}
            />
          </Box>
        ) : (
          <Box width={"100%"}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={1}>
              <Box display={"flex"} alignItems={"center"}>
                <ShortcutRounded
                  sx={{
                    transform: "scaleX(-1) rotate(180deg)",
                    color: theme.palette.action.disabled,
                  }}
                />
                <Box mr={1} />
                <Typography variant="subtitle2" color="textSecondary">
                  Follow-Ups
                </Typography>
              </Box>
              <Button
                onClick={() =>
                  handleMessageChange({ ...message, noScroll: true })
                }
                variant="text"
                sx={{ textTransform: "none", borderRadius: 24, paddingX: 1.5 }}
                endIcon={
                  <GradientIconAccent
                    Icon={ChevronRightRounded}
                    height={20}
                    width={20}
                  />
                }>
                <span
                  className={
                    classes.gradientText
                  }>{`View All (${numFollowUp})`}</span>
              </Button>
            </Box>
            <Box px={1.5} py={1} bgcolor={"#F5F5F5"} borderRadius={3}>
              {query["threads"]
                .slice(0, 2)
                .sort(
                  (msg1, msg2) =>
                    Date.parse(msg2["query"]["created_at"]) -
                    Date.parse(msg1["query"]["created_at"])
                )
                .map((threadMessage, i) => {
                  const { query } = threadMessage;
                  return (
                    <Box
                      key={i}
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleMessageChange(message)}>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}>
                        <Box width={"calc(100% - 56px)"}>
                          <Typography
                            variant="body2"
                            noWrap
                            sx={{ marginBottom: 0.5 }}>
                            <span
                              style={{
                                color: theme.palette.text.secondary,
                                marginRight: 4,
                                fontWeight: 500,
                              }}>
                              Q.
                            </span>
                            {query["content_object"]["text"]}
                          </Typography>
                          <Typography
                            display={"flex"}
                            alignItems={"center"}
                            variant="caption"
                            color="textSecondary"
                            sx={{ marginLeft: "18px" }}>
                            {getDate(query["created_at"])["part1"]}
                            <FiberManualRecord
                              sx={{
                                fontSize: 4,
                                color: theme.palette.text.secondary,
                                marginX: 0.5,
                              }}
                            />
                            {getDate(query["created_at"])["part2"]}
                          </Typography>
                        </Box>
                        <Box mr={3} />
                        <IconButton edge="end">
                          <ChevronRightRounded fontSize="small" />
                        </IconButton>
                      </Box>
                      {i === 0 && numFollowUp >= 2 && (
                        <Divider sx={{ marginY: 1 }} />
                      )}
                    </Box>
                  );
                })}
            </Box>
            <Box
              mt={2.5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}>
              <Chip
                classes={{ label: classes.label }}
                label={
                  <Typography
                    variant="subtitle2"
                    className={classes.gradientText}>
                    Ask a follow-up question
                  </Typography>
                }
                variant="outlined"
                color="secondary"
                icon={
                  <GradientIconAccent
                    Icon={ShortcutRounded}
                    sx={{ transform: "scaleX(-1) rotate(180deg)" }}
                  />
                }
                sx={{
                  borderRadius: 6,
                  borderTopLeftRadius: 0,
                  paddingLeft: 1.5,
                  borderColor: "#BF40AE",
                }}
                onClick={() => handleMessageChange(message)}
              />
            </Box>
          </Box>
        )}
      </Box>
      {answer["content_object"]["citations"] &&
        answer["content_object"]["citations"].length > 0 &&
        handleSourceChange && (
          <Sources
            citations={answer["content_object"]["citations"] || []}
            handleSourceChange={handleSourceChange}
          />
        )}
      {!isLastMessage && (
        <Box my={5}>
          <Divider />
        </Box>
      )}
    </Box>
  );
}

export function Query({ query, isFollowup }) {
  const { content_object, created_at } = query;
  const theme = useTheme();
  const { classes } = useStyles();
  return (
    <Box mb={5}>
      <Box display={"flex"} alignItems={"center"} mb={1}>
        <Typography variant="subtitle2" className={classes.gradientText}>
          {isFollowup ? "Follow-Up" : "Question"}
        </Typography>
        <FiberManualRecord
          sx={{ fontSize: 8, color: theme.palette.text.secondary, marginX: 1 }}
        />
        <Typography variant="caption" color={"textSecondary"}>
          {moment(created_at).format("h:mm A")}
        </Typography>
      </Box>
      <Typography
        variant={"h3"}
        fontWeight={500}
        sx={{ whiteSpace: "pre-wrap" }}>
        {content_object["text"]}
      </Typography>
    </Box>
  );
}

export function Answer({ answer, handleSourceChange }) {
  const theme = useTheme();
  const { content_object } = answer;

  return (
    <Box>
      <Box mb={2} display={"flex"} alignItems={"center"}>
        <NotesRounded sx={{ color: theme.palette.action.disabled }} />
        <Box mr={1} />
        <Typography variant="subtitle2" color={"textSecondary"}>
          Answer
        </Typography>
      </Box>
      <AnswerContent
        message={content_object["text"]}
        handleSourceChange={handleSourceChange}
      />
    </Box>
  );
}

export default Message;
