import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Header from "./Header";
import {
  getAllConversations,
  getPromptQuestions,
} from "../../core/repo/conversationRepo";
import Conversations from "./Conversations";
import Loader from "../../components/Loader";
import Messages from "./Messages";
import { v4 as uuidv4 } from "uuid";
import Page from "../../components/Page";
import { makeStyles } from "tss-react/mui";
import {
  FOLLOWUP_BUTTON_CLICK,
  SELECT_SESSION,
  START_SESSION,
} from "../../utils/posthogEvents";
import { usePostHog } from "posthog-js/react";
import { useDispatch, useSelector } from "react-redux";
import {
  setConversationSuccess,
  setMessageSuccess,
} from "../../core/events/sessionEvents";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%",
  },
}));

function Chats() {
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const { classes } = useStyles();
  const [conversations, setConversations] = useState([]);
  const { conversation, message } = useSelector((state) => state.sessions);
  const [promptQuestions, setPromptQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchData() {
    setIsLoading(true);
    try {
      const response = await getAllConversations();
      const conversations = response["conversations"] || [];
      setConversations(conversations);
      const promptResponse = await getPromptQuestions();
      const promptQuestions = promptResponse["prompt_questions"] || [];
      setPromptQuestions(promptQuestions);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const setConversation = (conversation) => {
    dispatch(setConversationSuccess(conversation));
  };

  const setMessage = (message) => {
    dispatch(setMessageSuccess(message));
  };

  const handleConversationChange = (conversation) => {
    setConversation(conversation);
    if (conversation) {
      posthog.capture(SELECT_SESSION);
    }
  };

  const handleMessageChange = (message) => {
    setMessage(message);
    if (message) {
      posthog.capture(FOLLOWUP_BUTTON_CLICK);
    }
  };

  const handleAddConversation = (conversation) => {
    setConversation(conversation);
    setConversations([conversation, ...conversations]);
  };

  const handleRemoveConversation = (conversation) => {
    setConversation(null);
    const tempConversations = conversations.filter(
      (c) => c["id"] !== conversation["id"]
    );
    setConversations(tempConversations);
  };

  const handleUpdateConversation = (conversation) => {
    setConversation(conversation);
    const tempConversations = conversations.map((c) => {
      if (c["id"] === conversation["id"]) {
        return conversation;
      }
      return c;
    });
    setConversations(tempConversations);
  };

  const handleNewChatClick = () => {
    const conversation = {
      id: uuidv4(),
      title: "New Session",
      isNew: true,
    };
    setConversation(conversation);
    setMessage(null);
    posthog.capture(START_SESSION);
  };

  return (
    <Page className={classes.root} title={"Sessions"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        position={"relative"}>
        <Header
          conversation={conversation}
          message={message}
          handleNewChatClick={handleNewChatClick}
          handleConversationChange={handleConversationChange}
          handleMessageChange={handleMessageChange}
          handleRemoveConversation={handleRemoveConversation}
          handleUpdateConversation={handleUpdateConversation}
        />
        <Box flex={1} overflow={"auto"}>
          {conversation ? (
            <Messages
              conversation={conversation}
              message={message}
              promptQuestions={promptQuestions}
              handleMessageChange={handleMessageChange}
              handleConversationChange={handleConversationChange}
              handleAddConversation={handleAddConversation}
            />
          ) : (
            <Conversations
              loading={isLoading}
              handleNewChatClick={handleNewChatClick}
              conversations={conversations}
              handleConversationChange={handleConversationChange}
            />
          )}
        </Box>
        {isLoading && <Loader />}
      </Box>
    </Page>
  );
}

export default Chats;
