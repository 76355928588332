import chatService from "../api/chatService";

export async function chatWithAI(
  query,
  conversationId,
  questionId,
  threadId,
  concise,
  fileIds,
  streamResponse
) {
  try {
    const response = await chatService.chatWithAI(
      query,
      conversationId,
      questionId,
      threadId,
      fileIds,
      concise
    );
    const reader = response.getReader();
    const decoder = new TextDecoder();

    let steamResp = "";
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;
      const newText = decoder.decode(value, { stream: true });
      steamResp += newText;
      streamResponse(steamResp);
    }
  } catch (error) {
    throw error;
  }
}

export async function getChatMetaData(questionId) {
  try {
    const response = await chatService.getChatMetaData(questionId);
    return response;
  } catch (error) {
    throw error;
  }
}
