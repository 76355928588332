import { getInstance } from "./axios";
import {
  CREATE_CONVERSATION_URL,
  GET_CONVERSATIONS_URL,
  GET_CONVERSATION_MESSAGES_URL,
  GET_PROMPT_QUESTIONS,
  DELETE_CONVERSATION_URL,
  UPDATE_CONVERSATION_URL,
} from "./url";

class ConversationService {
  createConversation = (accountId) =>
    new Promise((resolve, reject) => {
      getInstance()
        .post(CREATE_CONVERSATION_URL, {
          account_id: accountId,
        })
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  updateConversation = (conversation) =>
    new Promise((resolve, reject) => {
      getInstance()
        .put(UPDATE_CONVERSATION_URL.format(conversation["id"]), conversation)
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  deleteConversation = (conversationId) =>
    new Promise((resolve, reject) => {
      getInstance()
        .delete(DELETE_CONVERSATION_URL.format(conversationId))
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getAllConversations = () =>
    new Promise((resolve, reject) => {
      getInstance()
        .get(GET_CONVERSATIONS_URL)
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getConversationMessages = (conversationId) =>
    new Promise((resolve, reject) => {
      getInstance()
        .get(GET_CONVERSATION_MESSAGES_URL.format(conversationId))
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  getPromptQuestions = () =>
    new Promise((resolve, reject) => {
      getInstance()
        .get(GET_PROMPT_QUESTIONS)
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
}

const conversationService = new ConversationService();

export default conversationService;
