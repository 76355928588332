import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Divider,
  Box,
  AppBar,
  Card,
} from "@mui/material";
import EmptyConversation from "./EmptyConversation";
import { Query } from "../Messages/Query";
import { makeStyles } from "tss-react/mui";
import { Close, CloseRounded } from "@mui/icons-material";

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
  },
}));

function NewConversationDialog({
  promptQuestions,
  message,
  query,
  setQuery,
  handleStreamMessages,
  selectedFiles,
  setSelectedFiles,
  onClose,
}) {
  const { classes } = useStyles();
  return (
    <Dialog
      onClose={onClose}
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
      classes={{ paper: classes.root }}>
      <DialogTitle
        sx={{
          background: "#ffffff",
          display: "flex",
          alignItems: "center",
          boxShadow:
            "0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)",
        }}>
        <IconButton edge="start" onClick={onClose} sx={{ mr: 1 }}>
          <CloseRounded />
        </IconButton>
        <Typography variant="h4">New Session</Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: 900,
          margin: "0 auto",
          // padding: { xs: 1, md: 3 },
        }}>
        <Box flex={1} overflow={"auto"} sx={{ padding: 1 }} mt={3}>
          <EmptyConversation
            promptQuestions={promptQuestions}
            setQuery={setQuery}
          />
        </Box>
        {/* <Box p={1}> */}
        <Query
          query={query}
          setQuery={setQuery}
          message={message}
          handleStreamMessages={handleStreamMessages}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
        {/* </Box> */}
      </DialogContent>
    </Dialog>
  );
}

export default NewConversationDialog;
